import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark

  let featuredImg = getImage(
    frontmatter.featuredImage?.childImageSharp?.gatsbyImageData
  )

  return (
    <>
      <Seo />
      <Header useLinks={true} />
      <div className="insights">
        <section className="insight-post">
          <div className="insight-header">
            <div>
              <h1>{frontmatter.title}</h1>
              <h4>{frontmatter.date}</h4>
              <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>
            </div>
            <div className="right">
              <GatsbyImage image={featuredImg} alt={frontmatter.title} />
            </div>
          </div>
          <div className="container">
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html.replace(excerpt, "") }}
            />
          </div>
        </section>
      </div>
      <Footer useLinks={true} />
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(format: HTML)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 640)
          }
        }
      }
    }
  }
`
